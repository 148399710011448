import {
  APP_ID,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import {provideRouter, Router, RouteReuseStrategy} from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import {routes} from './app.routes';
import {provideClientHydration, withEventReplay} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {CSRFInterceptor} from './interceptors/csrf-interceptor';
import {JwtTokenInterceptor} from './rt-jwt-auth/interceptor/jwt-token.interceptor';
import {AuthErrorInterceptor} from './auth/interceptors/auth-error/auth-error.interceptor';
import {CustomRouteReuseStrategy} from './shared/classes/custom-route-reuse-strategy';
import {ConnectionServiceOptions, ConnectionServiceOptionsToken} from './rt-connection/rt-connection.service';
import {RouterStateSerializer, withNgxsRouterPlugin} from '@ngxs/router-plugin';
import {DataParamsRouterStateSerializer} from './rt-route-state-serializer/serializers';
import {CookieService} from 'ngx-cookie-service';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideStore} from '@ngxs/store';
import {environment, settings} from '../environments/environment';
import {WindowModule} from './universal/window/window.module';
import {RtTrackUserEventsModule} from './rt-track-user-events/rt-track-user-events.module';
import {formatTitle} from './shared/functions/format-title';
import {AuthState} from './auth/states/auth/auth.state';
import {RtRouterDataResolverState} from './rt-router-data-resolver/rt-router-data-resolver.state';
import {Settings} from '../conf/settings.base';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  MicrosoftLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import {RtHammerJsModule} from './rt-hammer-js/rt-hammer-js.module';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {RtDynamicScriptsModule} from './rt-dynamic-scripts/rt-dynamic-scripts.module';
import {HomeModule} from './home/home.module';
import {CookiesModule} from './shared/microservices/cookies/cookies.module';
import {PagesModule} from './pages/pages.module';
import {NgxEchartsModule} from 'ngx-echarts';
import {RtLoadProgressModule} from './rt-load-progress/rt-load-progress.module';
import {RtTitlesModule} from './rt-titles/rt-titles.module';
import {MicroservicesModule} from './shared/microservices/microservices.module';
import {withNgxsLoggerPlugin} from '@ngxs/logger-plugin';

const PROVIDERS: any[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CSRFInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthErrorInterceptor,
    multi: true,
  },
  {
    provide: RouteReuseStrategy,
    useClass: CustomRouteReuseStrategy,
  },
  {provide: APP_ID, useValue: 'brain-key-app'},
  {provide: Settings, useValue: settings},
  {provide: RouterStateSerializer, useClass: DataParamsRouterStateSerializer},
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider('188537416586-pqmtkfajdo04rf87quk668i27unfej52.apps.googleusercontent.com'),
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('4570321899659112'),
        },
        {
          id: MicrosoftLoginProvider.PROVIDER_ID,
          provider: new MicrosoftLoginProvider('b746e24f-11ec-401c-9b20-f4591e3ff2ba'),
        },
      ],
    } as SocialAuthServiceConfig,
  },
  {
    provide: ConnectionServiceOptionsToken,
    useValue: {
      enableHeartbeat: false,
      heartbeatInterval: 30000,
    } as ConnectionServiceOptions,
  },
  CookieService,
];

if (environment.production) {
  PROVIDERS.push(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      const initializerFn = (() => () => {})();
      return initializerFn();
    })
  );
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideStore([AuthState, RtRouterDataResolverState], withNgxsRouterPlugin()),
    importProvidersFrom(
      RtHammerJsModule,
      WindowModule,
      NgxPageScrollModule,
      NgxPageScrollCoreModule.forRoot({duration: 250}),
      MatSnackBarModule,
      SocialLoginModule,
      RtDynamicScriptsModule.forRoot({
        scripts: [{name: 'papaya', src: 'assets/scripts/papaya.js'}],
      }),
      HomeModule,
      CookiesModule.forRoot({EXCLUDE_ROUTES_LIST: ['demo-viewer']}),
      PagesModule,
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts/core'),
      }),
      RtLoadProgressModule.forRoot({
        PROGRESS_MIN_STEP: 10,
        NUMBER_OF_SYMBOLS_AFTER_COMMA: 0,
      }),
      RtTitlesModule.forRoot({
        PAGE_TITLE: settings.TITLE_DEFAULT,
        TITLE_PAGE_SEPARATOR: settings.TITLE_SEPARATOR,
        META_TAGS: settings.META_DEFAULT,
        TITLE_FORMATTER: formatTitle,
      }),
      RtTrackUserEventsModule.forRoot({
        TITLE_FORMATTER: formatTitle,
      }),
      MicroservicesModule
    ),
    ...PROVIDERS,
  ],
};
